:root {
  --main-color: rebeccapurple;
  --second-color: rgb(168, 131, 206);
  --dark-light: #191f3a;
  --white: #ffffff;
  --white-light: #c8c8c8;
  --shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  scroll-behavior: smooth;
  --cursive-font: "Dancing Script", cursive;
}
.header {
  background-color: var(--main-color);
  color: rebeccapurple;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 2%;
}
li {
  font-size: 30px;
  list-style: none;
}
.section {
  color: white;
  background-color: rebeccapurple;
}
.upper_section {
  height: 45px;
  display: flex;
  align-items: center;
}
.hidden {
  display: none;
}
.upper_section div {
  flex-basis: 50%;
  font-size: 30px;
  width: 100%;
  height: 100%;
}
.upper_section div .AdeChat {
  text-decoration: none;
  color: white;
  padding-left: 9%;
  font-size: 25px;
}
.upper_section ul {
  flex-basis: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 9%;
}
.upper_section ul .links {
  font-size: 45px;
  padding: 10px;
}

.container .link:hover,
.links:hover {
  background: rgba(1, 1, 1, 0.25);
  color: white;
}
.container .link:active,
.links:hover {
  background: rgba(1, 1, 1, 0.25);
  color: white;
}
.lower_section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 45px;
}
.lower_section ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.lower_section ul li {
  width: 100%;
  height: 100%;
}
.lower_section .link,
.upper_section .link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.lower_section .link .item,
.upper_section .link .item {
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  transition: all 0.5 ease;
}
