.settings-container {
  padding: 4%;
}
.settings-items {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 5%;
  margin-bottom: 4%;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease-in-out;
}
.settings-items span {
  padding-left: 2%;
}
.settings-items:active {
  background-color: rgb(212, 132, 212);
}
.settings-items:hover {
  background-color: rgb(230, 225, 230);
}
/* .upperContainer {
  top: 0;
  position: sticky;
  display: flex;
  width: 100%;
  height: 70px;
  background-color: rebeccapurple;
  align-items: center;
  color: white;
  padding: 1% 2%;
} */
