.share {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.shareWrapper {
  padding: 10px;
  display: flex;
}
.shareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgb(160, 72, 160);
  object-fit: cover;
  margin-right: 10px;
}

.shareInput {
  margin-left: 5%;
  border: none;
  width: 80%;
  height: 55%;
  font-size: 90%;
  font-weight: bold;
}
.opacity {
  background-color: rgb(162, 150, 173);
  cursor: not-allowed;
}
.opacity:hover {
  background-color: rgb(162, 150, 173);
  cursor: not-allowed;
}
.shareInput:focus {
  outline: none;
}

.shareBottom {
  background-color: yellow;
}
.shareTop {
  display: flex;
  align-items: center;
  flex: 3;
}

.shareOptions {
  display: flex;
  flex: 1;
  margin-left: 20px;
}

.shareOption {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.shareIcon {
  font-size: 18px;
  margin-right: 3px;
}

.shareOptionText {
  font-size: 14px;
  font-weight: 500;
}

.shareButton {
  border: none;
  padding: 7px;
  border-radius: 5px;
  background-color: green;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
  color: white;
}

.shareImgContainer {
  padding: 0 30px 20px 30px;
  position: relative;
  width: 200px;
  /* height: 300px; */
}

.shareImg {
  width: 100%;
  object-fit: cover;
}

.shareCancelImg {
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
  opacity: 0.7;
  border: 3px solid white;
}
