.chatBoxWrapper {
  display: flex;
  flex-direction: column;
}
.chatBoxWTop {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  background-color: rgb(243, 236, 243);
  /* flex: 9; */
  /* background-color: orange; */
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 15%;
}

.chatBoxBottom {
  display: flex;
  align-items: center;
  width: 100%;
  /* flex: 1; */
  position: fixed;
  bottom: 0;
  max-width: 900px;
  z-index: 100;
  margin-bottom: 4px;
}
.chatBoxBottom .chatBoxInput {
  font-size: 15px;
  font-weight: bold;
  width: 70%;
  height: 40px;
  padding: 10px;
  border: none;
  outline: none;
}

.emoji {
  position: relative;
}
.emoji svg {
  font-size: 1.5rem;
  color: rebeccapurple;
  cursor: pointer;
}
.emoji-container {
  /* width: 40%;
  bottom: 20px;
  top: 450px;
  background-color: red;
  height: 80vh;
  position: absolute; */
  /* display: none; */
  position: absolute;
  top: 150px;
}
.emoji-picker-react {
  /* position: absolute;
  background-color: yellow;
  top: 350px;
  height: 80vh;
  display: none; */
}

.icon {
  background-color: rebeccapurple;
}
.icon.send {
  height: 30px;
  width: 40px;
  padding: 5px 10px;
  margin: 0 2%;
}
