.profileCover {
  position: relative;
}

.profileCoverImg {
  width: 100%;
  object-fit: cover;
}

.profileUserImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 60%;
  border: 3px solid white;
}
.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.myProfile {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.myProfile h2 {
  margin-bottom: 1rem;
  margin-top: 12px;
}
.myProfileDetails {
  margin-top: 1rem;
  padding-left: 5%;
  width: 100%;
}
.myProfileDetails p {
  display: flex;
  margin: 5px 0;
  max-width: 300px;
}
.myProfileDetails p span {
  padding-left: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}
.myProfileDetails p span h3 {
  margin-left: 3px;
}
.edit {
  /* background-color: red; */
  display: flex;
  align-items: center;
}
